.BoxShadowHelper(@level: 1) {
    & when (@level =1) {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    & when (@level =2) {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    & when (@level =3) {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    & when (@level =4) {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    & when (@level =5) {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    }
    & when (@level =6) {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0), 0 3px 2px rgba(0, 0, 0, 0.24);
    }
}

.depth-1 {
    .BoxShadowHelper(1);
}

.depth-2 {
    .BoxShadowHelper(2);
}

.depth-3 {
    .BoxShadowHelper(3);
}

.depth-4 {
    .BoxShadowHelper(4);
}

.depth-5 {
    .BoxShadowHelper(5);
}

.depth-6 {
    .BoxShadowHelper(6);
}

#color(@color) {
    color: @color;
}

#bgcolor(@color) {
    background-color: @color;
}
