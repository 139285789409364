@import "../../app-name.less";

.@{APP_NAME} {
    &--table-search {
        margin: 4px auto;
        width: ~"calc(100% - 8px)";
    }

    &--table-wrapper {
        position: relative;

        .ant-spin-nested-loading,
        .ant-spin-container {
            position: unset;
        }

        .ant-pagination.ant-table-pagination {
            float: none;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .hideSelection {
            .ant-table-selection-column {
                margin: 0 !important;
                max-width: 0 !important;
                min-width: 0 !important;
                overflow: hidden !important;
                padding: 0 !important;
                width: 0 !important;
            }
        }
    }

    &--actions {
        opacity: 0;
        position: absolute;
        transform: translate(0, -50%);
        transition: opacity 300ms;
    }

    .ant-table-row-selected &--actions,
    .ant-table-row:hover &--actions {
        opacity: 1;
    }
}

tr.clickable {
    cursor: pointer;
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);