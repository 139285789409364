.fade {
    &-enter,
    &-exit {
        transition: opacity 200ms linear;
    }

    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
        }
    }
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);