@import (reference) "~cg-style/co-ui-default";
@import "../../app-name.less";

@tab-height: 40px;

.@{APP_NAME} {
    &--panel {
        padding: 0 20px 20px 20px;
        min-height: 600px;

        &-inner {
            flex-grow: 1;
            background-color: @clr-white;
            box-shadow: 0 5px 10px 0 fade(@clr-darkest, 20%);
        }

        &-tabs {
            z-index: 1;
            display: flex;
            height: @tab-height;
        }

        &-tab {
            background: @clr-white;
            line-height: @tab-height;
            margin-right: 10px;
            opacity: 0.6;
            transition: opacity 300ms;
            text-transform: uppercase;

            & > * {
                padding: 0 25px;
            }

            &:hover {
                opacity: 0.85;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);