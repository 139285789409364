@import (reference) "~cg-style/co-ui-default.less";

@clr-border: @clr-mercury;
//  Card colors
@bg-card: #fff;
@bg-card-premium: rgba(255, 255, 255, 0.79);
//  Cell colors
@clr-primary: @clr-lochmara; //  blue
@clr-tertiary: @clr-international-orange; // orange
@clr-quaternary: @clr-paper-gray; //gray
//
@border-selected: #b7cad4; // @clr-deep-cerulean;
@bg-selected-card: #edf7ff;
@bg-selected-card-route: #dfedf7;

//  Location-route
@bg-location-wrapper: #f7f9fa; // @clr-concrete;
//  Currency color
@clr-currency-pos: @clr-japanese-laurel;
@clr-currency-neg: @clr-red;
//  Datetime
@bg-time-zone: @clr-red;
@clr-time-zone: @clr-white;
@size-time-zone: 0.8em;
//  Product class
@fill-product-class: @clr-dove-gray;
@fill-product-class-hl: @clr-jazzberry-jam;
@size-product-class: 24px;
//  schedule
@stroke-schedule: @clr-lochmara;
@fill-loadability-1: @clr-lochmara;
//  Cell fonts
@font-size-primary: 15px;
@font-weight-primary: 500;
@margin-between-columns: 10px;
//  Header buttons
@clr-sort-button: @clr-cloudy-gray;
@clr-sort-button-active: @clr-primary;
//  Action menu
@action-menu-width: 20px;
@action-menu-btn-size: 24px;
@fill-menu-btn: @clr-dove-gray;
@fill-menu-btn-hover: @clr-serious-gray;
//  Premium Rate
@bg-premium-card-container: #e4d48f;
@border-premium-card-container: 1px #c3b18f solid;
@clr-premium-card-caption: #8c6712;
//  Target drop
@clr-border-target: #e4e4e4;
@clr-border-target-drop-me: #b4d595; // lighten(@clr-japanese-laurel, 10%);
//
@shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
@padding-card-view: 40px;

// MIXIN
.marginBetweenColumns {
    padding-left: @margin-between-columns;

    &:first-child {
        padding-left: 0;
    }
}

// CSS

.cg-ui-card-view {
    color: @clr-mine-shaft;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    .premium-card-container,
    .view-header,
    .header-search-container {
        padding: 0 @padding-card-view;
    }

    &.no-expandable-card-view {
        .premium-card-container,
        .target-card-container,
        .view-header,
        .header-search-container {
            padding: 0 0;
        }
    }

    .unselectable-text {
        user-select: none;
    }

    .view-body {
        .cg-ui-card {
            width: 100%;
            background-color: @bg-card;
            border: 1px solid @clr-border;
            transition: margin cubic-bezier(0.01, 0.76, 1, 0.99) 0.2s, box-shadow cubic-bezier(0.01, 0.76, 1, 0.99) 0.2s;
            .BoxShadowHelper(1);
            @expand-margin: 20px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0);
            min-height: 74px;
            position: relative;

            &:hover {
                border: 1px solid @clr-cloudy-gray;
                z-index: 4;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0);
            }

            &.expanded {
                background-color: #f5f7fa;
                margin: @expand-margin - (@expand-margin * 1.5) @expand-margin - (@expand-margin * 0.5);
                border: none;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.32);

                .expand-container {
                    display: initial;
                }

                .expand-child-wrapper {
                    padding-bottom: @expand-margin;

                    .cg-ui-card-body.collapsed {
                        cursor: default !important;
                    }
                }

                .cg-ui-card {
                    margin: 0 auto;
                    width: 95%;

                    &.expanded {
                        margin: 20px auto;
                    }
                }
            }

            .expand-container {
                display: none;
            }

            &.selected {
                border-color: @border-selected;
                position: relative;
                background-color: @bg-selected-card;
                z-index: 2;

                + .selected {
                    border-top-color: #fff;
                }

                .check-box-column {
                    .checked {
                        display: inline-block !important;
                    }

                    .un-checked {
                        display: none !important;
                    }
                }
            }

            // Checkbox
            .cg-ui-card-body .card-column.check-box-column {
                flex: none;
                width: 0;
                flex-direction: row;
                align-items: center;
                transition: width ease-in-out 0.1s;
                box-sizing: border-box;

                .check-box-wrapper {
                    margin-left: 10px;

                    .checked {
                        display: none;
                    }

                    .un-checked {
                        display: inline-block;
                    }
                }
            }

            &.check-mode {
                .check-box-column {
                    width: 30px !important;
                }
            }

            .cg-ui-card-body {
                display: flex;
                white-space: nowrap;
                box-sizing: border-box;
                padding-right: @margin-between-columns;
                min-height: inherit;
                cursor: default;

                .card-column-group {
                    flex: 1;
                    display: flex;
                    overflow: hidden;
                    .marginBetweenColumns;
                }

                .card-column {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .marginBetweenColumns;
                    overflow: hidden;
                    padding-bottom: 4px;
                    padding-top: 4px;

                    &no-padding {
                        padding: 0;
                    }

                    > * {
                        flex: 1;
                        display: flex;
                    }

                    &.indicator-column {
                        flex: none;
                        width: 5px;
                        padding: 0;

                        .indicator {
                            height: 100%;
                            width: 5px;

                            span {
                                width: 5px;
                                height: 100%;
                            }
                        }
                    }

                    &.action-column {
                        flex: none;
                        width: @action-menu-width;
                        margin: 0;
                        position: relative;
                        flex-direction: row;
                        align-items: center;
                        min-height: 30px;

                        &:hover {
                            overflow: visible;
                        }

                        .action-wrapper {
                            fill: @fill-menu-btn;

                            .more {
                                &:hover {
                                    fill: @fill-menu-btn-hover;
                                }

                                display: flex;
                                align-items: center;
                            }

                            .btn-container {
                                position: absolute;
                                right: @action-menu-width;
                                top: 0;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                background-color: @bg-card;
                                border-left: 1px solid @clr-border;
                                padding: 0 @action-menu-btn-size;

                                .action-button {
                                    height: @action-menu-btn-size;
                                    display: inline-block;
                                    margin: 2px;
                                    flex: none;

                                    &:hover {
                                        background-color: @clr-border;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                .card-cell {
                    line-height: 1.4em;
                    flex-direction: row;
                    align-items: center;

                    > div {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    > span {
                        display: block;
                        max-width: 100%;

                        > div {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    &.primary,
                    &.secondary {
                        font-weight: @font-weight-primary;
                        font-size: @font-size-primary;
                        line-height: 1.4em;
                    }

                    &.primary {
                        color: @clr-primary;
                        fill: @clr-primary;
                    }

                    &.tertiary {
                        color: @clr-tertiary;
                        fill: @clr-tertiary;
                    }

                    &.quaternary {
                        color: @clr-quaternary;
                        fill: @clr-quaternary;
                    }

                    &.currency {
                        justify-content: flex-end;

                        > span {
                            display: inline-block;
                        }

                        > .wrapper-currency-value {
                            display: flex;
                            align-items: center;

                            &.pos {
                                color: @clr-currency-pos;
                            }

                            &.neg {
                                color: @clr-currency-neg;
                            }

                            &.button {
                                cursor: pointer;
                            }
                        }

                        .card-cell-sign {
                            margin-right: 1em;
                        }
                    }

                    &.datetime {
                        justify-content: flex-end;

                        .time-zone {
                            background-color: @bg-time-zone;
                            color: @clr-time-zone;
                            display: inline-block;
                            padding: 0 0.2em;
                            height: 1.4em;
                            margin-left: 0.5em;
                            font-size: @size-time-zone;
                            line-height: 1.4em;
                            text-align: center;
                        }
                    }

                    &.two-values {
                        justify-content: center;

                        .wrapper-values {
                            display: flex;
                            align-items: center;

                            .val {
                                flex: 1;
                            }

                            > span {
                                flex: none;
                                padding: 0 0.5em;
                                display: flex;
                            }
                        }
                    }

                    &.operating-days {
                        .noFrequency {
                            color: @clr-paper-gray;
                            margin-right: 2px;
                        }
                    }

                    &.product-class {
                        &.clickable {
                            cursor: pointer;
                        }

                        .product-class-wrapper {
                            > span {
                                //  Tooltip
                                display: flex;
                            }

                            fill: @fill-product-class;
                            display: flex;
                            flex-wrap: wrap;

                            i.icon {
                                border: 1px solid @fill-product-class;
                                border-radius: 2px;
                                margin: 2px;
                                height: @size-product-class;
                                box-sizing: content-box;

                                &.size__32 {
                                    border-width: 2px;
                                    border-radius: 4px;
                                }

                                &.size__36 {
                                    border-width: 2px;
                                    border-radius: 5px;
                                }

                                &.size__48 {
                                    border-width: 2px;
                                    border-radius: 6px;
                                }

                                &.highlight {
                                    border: 1px solid @fill-product-class-hl;
                                    fill: @fill-product-class-hl;
                                }

                                &.cao {
                                    border: 1px solid @clr-international-orange;
                                }
                            }
                        }
                    }

                    &.button {
                        cursor: pointer;
                    }
                }

                .schedule-route {
                    background-color: @bg-location-wrapper;
                    padding-top: 4px;

                    .svg-wrapper {
                        display: inline-block;
                        position: relative;
                    }

                    .route-path {
                        stroke: @stroke-schedule;
                    }

                    .route-curve-img {
                        fill: @bg-location-wrapper;
                    }

                    .voyage-no {
                        color: @clr-primary;
                        font-weight: 500;
                    }

                    .vehicle {
                        position: absolute;
                    }

                    .loadability-1 {
                        fill: @fill-loadability-1;
                    }
                }

                .card-route {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    &.button {
                        cursor: pointer;
                    }

                    .marginBetweenColumns;

                    .route-wrapper {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        background-color: rgba(210, 233, 245, 0.25);
                        padding: 4px 0;

                        .locations {
                            flex: 1;
                            display: flex;

                            .location {
                                flex-direction: row;
                                justify-content: center;
                                flex: 1;
                                display: flex;
                                overflow: hidden;
                                align-items: center;

                                .name {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    color: @clr-paper-gray;
                                    line-height: 1.4em;
                                    height: 21px;
                                }

                                .code {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-weight: @font-weight-primary;
                                    font-size: @font-size-primary;
                                    padding: 4px 0 2px 0;
                                    display: flex;
                                    flex-flow: row nowrap;
                                    align-items: center;

                                    span {
                                        flex-grow: 1;
                                    }
                                }

                                .location-wrapper {
                                    padding: 0 8px;
                                    display: flex;
                                    overflow: hidden;
                                    flex-direction: column;

                                    > .code > span + span::before {
                                        content: ", ";
                                    }

                                    &.button {
                                        cursor: pointer;
                                    }
                                }

                                &.route-from {
                                    text-align: end;
                                    justify-content: flex-end;
                                }

                                &.route-to {
                                    justify-content: flex-start;
                                }
                            }

                            .direction {
                                flex: 0 0 auto;
                                display: flex;
                                align-items: center;
                                padding-top: 25px;
                            }
                        }

                        .waypoints {
                            display: flex;
                            justify-content: center;
                            color: @clr-paper-gray;

                            &:after {
                                content: "\200b";
                            }

                            .waypoints-via-label {
                                padding-right: 1em;
                                font-size: 80%;
                            }

                            .button {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .view-header {
        margin: 10px 0;

        .cg-ui-header {
            display: flex;
            white-space: nowrap;
            box-sizing: border-box;
            padding: 4px 0;
            cursor: default;
            line-height: 1.4em;
            font-size: 100%;

            .header-column-group {
                flex: 1;
                display: flex;
                overflow: hidden;
                .marginBetweenColumns;
            }

            .header-column {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                .marginBetweenColumns;
                align-items: center;

                .caption-wrapper {
                    padding-left: 20px; //   compensate spave buttons
                    &.sort-enabled {
                        cursor: pointer;
                    }

                    .sort-buttons {
                        display: inline-block;
                        width: 16px;
                        vertical-align: middle;

                        .sort-button {
                            margin-left: 0.5em;
                            width: 0;
                            height: 0;
                            display: block;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;

                            &.up {
                                border-bottom: 6px solid @clr-sort-button;
                                margin-bottom: 2px;

                                &.active {
                                    border-bottom-color: @clr-sort-button-active;
                                }
                            }

                            &.down {
                                margin-top: 2px;
                                border-top: 6px solid @clr-sort-button;

                                &.active {
                                    border-top-color: @clr-sort-button-active;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-search-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-wrapper {
            display: flex;
            flex: 1;

            .search-input {
                border: 1px solid @clr-cloudy-gray;
                flex: 1;
                height: 3em;
                box-sizing: border-box;
                padding: 0 1em;
            }
        }

        .action-button-wrapper {
            @size: 40px;
            flex: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 0 @size / 2;

            .button {
                width: @size;
                height: @size;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color ease-in-out 0.1s;

                &:hover {
                    background-color: @clr-primary;
                }
            }
        }
    }

    .target-card-container {
        padding-left: 40px;

        &.drop-me {
            .view-body {
                border-color: @clr-border-target-drop-me;
            }
        }

        .view-body {
            padding: 10px;
            border: 4px @clr-border-target dashed;
            transition: border-color ease-in-out 0.2s;

            > .message-none {
                text-align: center;
                color: #bdbdbd;
                font-size: @font-size-primary;
            }

            > .caption {
                line-height: 1.5em;
            }
        }
    }

    .premium-card-container {
        margin: 0 0 20px 0;

        .view-body {
            padding: 10px;
            border: @border-premium-card-container;
            background-color: @bg-premium-card-container;

            > .caption {
                line-height: 1.5em;
                color: @clr-premium-card-caption;
            }

            .cg-ui-card {
                background-color: @bg-card-premium;

                .card-route .route-wrapper {
                    background-color: initial;
                }
            }
        }
    }
}

span.viasArray > *:not(:last-child):after {
    content: ", ";
}

span.viasInnerArray > *:not(:last-child):after {
    content: "-";
}

.removeTargetCardButton {
    background: transparent;
    border: none;
    color: @clr-primary;
    cursor: pointer;
}

.DeckType_1 svg {
    border-bottom: 3px solid @clr-primary;
}

.DeckType_2 svg {
    border-top: 3px solid @clr-primary;
}

.card-cell-currency {
    display: inline-block;
    margin-left: 4px;
    padding-top: 0.2em;
    font-size: 60%;
    text-align: start;
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);