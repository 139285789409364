@import (reference) "../search.less";

.location-search-item {
    display: flex;
    align-items: center;
    padding: 4px (@margin / 2 + 40px) 4px (@margin / 2);
    position: relative;
    height: 50px;
    cursor: pointer;

    .icon {
        width: 35px;
        margin-right: 5px;

        &.sublocation {
            margin-left: 1em;
        }

        svg {
            fill:currentColor;
        }
    }

    .labels {
        flex: 1;
        overflow: hidden;

        .name-row {
            line-height: 1.4;
            font-size: 15px;
            font-weight: normal;
            white-space: nowrap;
            display: flex;
        }

        .name {
            .textEllipse();

            flex-shrink: 1;
        }

        .code {
            flex-shrink: 0;
            padding-left: 0.2em;
        }

        .description {
            .textEllipse();

            line-height: 1.4;
            font-size: 13px;
        }
    }

    .wrapper-btn {
        align-items: center;
        bottom: 0;
        display: flex;
        position: absolute;
        right: @margin / 2;
        top: 0;
    }

    .wrapper-button-icon {
        fill: @clr-cloudy-gray;

        &:hover {
            fill: @clr-deep-cerulean;
        }

        &.favorite {
            fill: @clr-orange-peel;
        }

        &.not_favorite {
            fill: @clr-cloudy-gray;

            &:hover {
                fill: @clr-orange-peel;
            }
        }
    }

    .circle {
        opacity: 0;
        transition: opacity 0.3s;

        &.favorite {
            opacity: 1;
            fill: @clr-orange-peel;
        }
    }

    &:hover {
        padding-right: @margin / 2 + 80px;
        background-color: @clr-concrete;

        .circle {
            opacity: 1;
        }
    }

    &.subselected {
        background-color: lighten(@clr-cerulean, 40%);
    }

    &.focused {
        background-color: @clr-cerulean;
        color: @clr-white;
    }
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);