
/* 
This adds some styling to react-datepicker to make it look a bit more like the antd picker
This helps us gradually phasing out the antdpicker and use react-datepicker instead. 
*/

.react-datepicker.calendar-antd-resemblance {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    border-radius: 0;
    border:none;
}

.react-datepicker.calendar-antd-resemblance.popper {
    border:1px solid #eee;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.calendar-antd-resemblance .react-datepicker__day--selected,
.calendar-antd-resemblance .react-datepicker__day--keyboard-selected,
.calendar-antd-resemblance .react-datepicker__day:hover.react-datepicker__day--selected:hover,
.calendar-antd-resemblance .react-datepicker__day:hover.react-datepicker__day--keyboard-selected:hover {
    background-color: rgb(166,237,255);
    border-color: transparent;
}

.calendar-antd-resemblance .react-datepicker__day.react-datepicker__day--today {
    color: #039BE5;
    font-weight: bold;
    border:1px solid #039BE5;
}

.calendar-antd-resemblance .react-datepicker__day:hover {
    background-color: #e6fbff;
}

.calendar-antd-resemblance .react-datepicker__day {
    border-radius: 2px;
    color:rgba(0, 0, 0, 0.65);
    width: 28px;
    height:28px;
}

.calendar-antd-resemblance .react-datepicker__header {
    background-color: unset;
    border:none;
}

.calendar-antd-resemblance .react-datepicker__current-month {
    font-weight: normal;
    color:unset;
    font-size:14px;
    padding-bottom:8px;
}

.calendar-antd-resemblance .react-datepicker__day-names {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
}

.calendar-antd-resemblance .react-datepicker__day-name {
    font-weight: normal;
    color:rgba(0, 0, 0, 0.65);
}

.calendar-antd-resemblance .react-datepicker__navigation {
    border:none;
    text-indent: unset;
    display: hidden;
    height:16px;
    width:24px;
    font-size:0;

}

.calendar-antd-resemblance .react-datepicker__navigation--next {
    right:0;
}

.calendar-antd-resemblance .react-datepicker__navigation--previous {
    left:0;
}

.calendar-antd-resemblance .react-datepicker__navigation::after {
    position: absolute;
    top:4px;
    left:8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border: 0 solid #aaa;
    border-width: 1.5px 0 0 1.5px;
    border-radius: 1px;
    content:''
}

.calendar-antd-resemblance .react-datepicker__navigation--next::after {
    transform: rotate( 135deg) scale(0.8);
}

.calendar-antd-resemblance .react-datepicker__navigation--previous::after {
    transform: rotate( 315deg) scale(0.8);
}

.calendar-antd-resemblance.react-datepicker.popper .react-datepicker__triangle {
    border-bottom-color: #fff;  
    border-top-color: #fff;  
}

.calendar-antd-resemblance.react-datepicker.popper .react-datepicker__triangle::before
{
    border-bottom-color: #eee;  
    border-top-color: #eee;  
}

.calendar-antd-resemblance .react-datepicker-time__header {
    font-size: 14px;
    font-weight: normal;
    color:rgba(0, 0, 0, 0.65);
    
}

.calendar-antd-resemblance .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
    border-bottom: 1px solid #eee;
}

.calendar-antd-resemblance .react-datepicker__time-container,
.calendar-antd-resemblance .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
{
    width:125px;
}

.calendar-antd-resemblance .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    color:rgba(0, 0, 0, 0.65);
}

.calendar-antd-resemblance .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: rgb(166,237,255);
    color:rgba(0, 0, 0, 0.65);
}

.calendar-antd-resemblance .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: #e6fbff;
}


/* 
Stuff for the time picker scrollbar. Its not perfect in FF yet, because
it doesn't have rounded corners in the thumb, and the scrollbar doesn't overlay the content.
But instead of fixing that i suggest writing a totally different time picker that is actually useful. 
*/ 
.calendar-antd-resemblance .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
    overflow: overlay;
    scrollbar-color: grey transparent;
    scrollbar-width: thin;

}

.calendar-antd-resemblance .react-datepicker__time-list::-webkit-scrollbar
{
    width: 7px;
}
   
.calendar-antd-resemblance .react-datepicker__time-list::-webkit-scrollbar-thumb
{
    background-color: rgb(0,0,0, 0.15);
    transition: background-color 200ms ease-in-out;
    border-radius: 3.5px;
}

.calendar-antd-resemblance .react-datepicker__time-list::-webkit-scrollbar-thumb:hover
{
    background-color: rgb(0,0,0, 0.75);
}