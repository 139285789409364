@import (reference) "~cg-style/co-ui-default";
@import "../../app-name.less";

.@{APP_NAME} {
    &--coverage {
        &-list {
            flex: 0 0 400px;
        }

        &-location {
            position: relative;

            &-row {
                align-items: center;
                border-bottom: 1px solid @clr-mercury;
                height: 70px;
                transition: background-color 300ms;

                &:hover {
                    background-color: fade(@clr-cerulean, 40%);
                }

                &-status-indicator {
                    border-radius: 50%;
                    display: inline-block;
                    height: 10px;
                    width: 10px;

                    &-col {
                        flex: 0 0 20px;
                        text-align: center;
                    }

                    &.inRates:not(.inSchedules) {
                        background-color: @clr-red;
                    }

                    &.inRates.added:not(.inSchedules) {
                        background-color: @clr-jungle-green;
                    }

                    &.inRates.inSchedules {
                        background-color: @clr-japanese-laurel;
                    }

                    &.inSchedules:not(.inRates) {
                        background-color: @clr-mulberry;
                    }
                }

                &-icon {
                    flex: 0 0 40px;
                    text-align: center;
                }

                &-pop {
                    flex: 0 0 20px;
                }
            }

            > input {
                opacity: 0;
                position: absolute;

                &:disabled + .@{APP_NAME}--coverage-location-row {
                    background-color: fade(@clr-cloudy-gray, 25%);
                }

                &:focus + .@{APP_NAME}--coverage-location-row {
                    box-shadow: inset 0 0 20px fade(@clr-cerulean, 75%);
                }

                &:checked + .@{APP_NAME}--coverage-location-row {
                    background-color: fade(@clr-cerulean, 75%);
                }
            }
        }
    }
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);