@import "~mapbox-gl/dist/mapbox-gl.css";
#mapboxContainer {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: #a8ccff;
}
#mapboxContainer canvas {
  outline: none;
}
#mapboxContainer > * {
  opacity: 0;
  transition: opacity 300ms;
}
#mapboxContainer.loaded > * {
  opacity: 1;
}
