@import (reference) "~cg-style/co-ui-default.less";

#buttonFills(@color, @bgcolor) {
    background-color: @bgcolor;
    color: @color;

    &.disabled {
        background-color: fade(@bgcolor, 40%) !important;
        color: fade(@color, 80%) !important;
    }

    &:hover {
        & when (lightness(@bgcolor) < 50%) {
            background-color: lighten(@bgcolor, 20%);
        }

        & when (lightness(@bgcolor) >=50%) {
            background-color: darken(@bgcolor, 20%);
        }
    }
}

@waypointColors: @clr-origin, @clr-via-1, @clr-via-2, @clr-destination;
@searchBackground: @clr-ribbon;
@widthControl: 420px;
@margin: 20px;

.locationSider {
    width: @widthControl !important;
    flex-shrink: 0;
}

.location-input {
    opacity: 0.8;
    min-width: 2em;
    border: 0;
}

.button-container(@background-color, @color, @hover) {
    .button-container {
        top: 0;
        bottom: 1px;
        flex-shrink: 0;
        overflow: hidden;
        max-width: 40px;
        min-width: 40px;
        transition: max-width 0.2s;
        padding-right: 10px;
        fill: @color;
        display: flex;

        .show-more {
            box-sizing: border-box;
            background-color: @background-color;
            height: inherit;
            width: 40px;
            overflow: hidden;
            transition: width 0.1s;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 1px;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .drawer {
            background-color: @background-color;
            display: flex;
            align-items: center;
            top: 0;
            bottom: 0;
            white-space: nowrap;

            .wrapper-button-icon {
                margin: 5px;

                &:hover {
                    fill: @hover;
                }
            }
        }

        &:hover {
            .show-more {
                border: 0;
                width: 0;
            }

            max-width: 200px;
        }
    }
}

.location-bar-container {
    ul {
        margin: 0;
        padding: 0;
    }

    .magnifier {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    li.item {
        display: flex;
        position: relative;
        align-items: center;
    }

    .result-labels {
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        ul {
            .textEllipse();

            display: flex;
            max-height: 50%;
        }

        li {
            .textEllipse();

            position: relative;
            margin: 0 0 0 0;
            align-items: center;
            display: inline-block;
        }

        ul.code {
            // padding-top: 1em
            font-size: 13px;
            font-weight: 400;
        }

        .code {
            margin-right: 1em;
        }

        ul.description {
            color: fade(@clr-white, 80%);
            white-space: nowrap;
            font-size: 15px;
            font-weight: 300;
            margin-right: 1em;
            font-style: italic;
        }
    }

    .ui-location-bar {
        height: 60px;
        display: flex;
        flex-flow: row nowrap;
        align-content: flex-end;
        transition: width ease-in-out 0.5s;
        color: @clr-white;
        user-select: none;

        .item {
            .waypointIcon {
                margin: 0 1em 0 2em;
            }

            cursor: pointer;
            display: flex;
            align-items: center;
            min-width: 100px;
            position: relative;
            #loopWaypoints(1);

            &:first-child {
                flex-shrink: 0;
            }

            &:last-child {
                flex-shrink: 0;
                background-color: extract(@waypointColors, length(@waypointColors));

                &:hover {
                    background-color: darken(extract(@waypointColors, length(@waypointColors)), 15%);
                }
            }

            .splitter {
                position: absolute;
                right: 0;
                top: 0;
                transform: translate(100%, 0);
                z-index: 1;

                > svg {
                    height: 60px;
                }
            }

            &:last-child .splitter {
                display: none;
            }
        }
    }

    .blur {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: fade(@clr-mine-shaft, 20%);
    }

    position: relative;
    fill: @clr-white;
}

.ui-location-search {
    input:focus {
        outline: none;
    }

    @height: 50px;
    @half: @height / 2;
    @font-size-input: 17px;
    @clr-bg: @searchBackground;
    @clr-text: @clr-white;

    color: @clr-text;
    position: relative;
    background-color: @searchBackground;

    &.focus {
        background-color: lighten(@searchBackground, 15%);
        .button-container(lighten(@searchBackground, 15%), @clr-text, darken(@clr-text, 15%));

        .wrapper {
            background-color: @searchBackground;
        }

        .border-bottom {
            border-bottom: 0 !important;
        }
    }

    .button-container(@searchBackground, @clr-text, darken(@clr-text, 15%));

    .input-wrapper {
        .icon {
            flex: 0 0 auto;
            display: flex;
            width: 30px;
            align-items: center;
            justify-content: center;
        }

        .border-bottom {
            display: flex;
            flex-grow: 1;
            border-bottom: 1px solid @clr-dove-gray;
            height: 100%;
            box-sizing: border-box;
            margin-top: auto;
            margin-bottom: auto;
            overflow: hidden;
        }

        .wrapper {
            border-radius: 5px;
            display: flex;
            margin: auto 0;
            height: 38px;
            overflow: hidden;
            flex-grow: 1;
            flex-shrink: 1;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            overflow: hidden;

            li {
                .textEllipse();

                flex: 0 1 auto;
                display: inline-block;

                &::after {
                    content: ", ";
                    padding-right: 0.2em;
                }

                &:last-child::after {
                    content: "";
                    padding-right: 0.2em;
                }
            }
        }

        height: @height;
        display: flex;
        position: relative;

        div {
            overflow: hidden;
        }

        .display {
            flex: 0 1 auto;
            cursor: default;
            white-space: nowrap;
            display: flex;
            overflow: hidden;
            align-items: center;
            margin: 0 4px 0 10px;

            .active {
                display: flex;
                align-items: center;
                height: @height;
                font-size: @font-size-input;

                span {
                    .textEllipse();

                    padding-right: 4px;

                    &::after {
                        content: ", ";
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        input {
            flex: none;
            border: none;
            margin: 0;
            font-size: @font-size-input;
            width: 10px;
            background-color: transparent;
            color: @clr-bg;

            &::-ms-clear {
                display: none;
            }
        }

        line {
            display: inline-block;
            position: absolute;
            border-left: 2px dotted @clr-white;
            z-index: 1;
            width: 1px;
            left: 14px;
            height: @height;
        }

        &.wpt__1 {
            line {
                /* Origin */
                top: @half+10px;
                height: @half;
            }
        }

        &.wpt__11 {
            line {
                /* via */
                top: @half+10px;
                height: @half;
            }
        }

        &.wpt__12 line {
            /* via */
            top: @half+10px;
            height: @half;
        }

        &.wpt__20 {
            fill: @clr-destination;

            line {
                /* Destination */
                // display: none;
                top: 0;
                height: 10px;
            }
        }

        .input-icon {
            .circle {
                border-radius: 50%;
                height: 10px;
                width: 10px;
                background-color: @clr-bg;
                border: 2px solid @clr-nav-bar;
                display: inline-block;

                &.departure {
                    border-color: @clr-origin;
                }

                &.via1 {
                    border-color: @clr-nav-bar;
                }

                &.via2 {
                    border-color: @clr-nav-bar;
                }
            }

            .map-marker {
                fill: @clr-origin;
            }
        }

        &.has__focus {
            /* .display {
          flex: none;
      } */

            input {
                display: inline-block;
                color: @clr-text;
                flex: 1 1 auto;
            }
        }

        &:hover {
            .more {
                display: inline-block;
            }
        }
    }
}

.ui-location-waypoints {
    pointer-events: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-height: 100%;
    transition: all 0.2s;

    &.disabled {
        pointer-events: none;
        opacity: 0.1;
        max-height: 0;
    }

    .searchfields {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .search-menu {
        background-color: @clr-white;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: @margin 0 0 0;

        header {
            position: relative;
            padding: @margin;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;

            .wrapper-button-icon {
                margin-right: 1em;
            }

            h1 {
                .textEllipse(); /* stylelint-disable-line */

                flex: 1;
                display: inline-block;
            }

            .btn-wrapper {
                position: relative;
                right: 0;
                top: 0;
                display: flex;
            }

            .cg-button {
                #buttonFills(white, orange);
            }
        }

        .wrapper-search-modes {
            font-size: 13px;
            line-height: 2.3em;

            button {
                background: none;
                border: none;
                color: @clr-dove-gray;
                cursor: pointer;
                margin: 0;
                padding: 0;

                & + button {
                    margin-left: 8px;
                    padding-left: 6px;
                }

                &:hover {
                    text-decoration: underline;
                }

                &.selected {
                    cursor: default;
                    text-decoration: underline;
                }
            }
        }

        .search-caption {
            color: @clr-dove-gray;
            font-style: italic;
            font-size: 20px;
        }

        .search-summary {
            flex-grow: 1;
        }

        /* Selected locations */

        .selected {
            flex: none;
            margin: 10px 10px 10px @margin / 2;

            .container {
                background-color: @clr-positive;
                padding-right: 0;
            }
        }

        .sugestions-header {
            flex: none;
            margin: 10px @margin;
            font-style: italic;
            font-size: 12px;
        }

        .suggestions {
            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            padding: 0;
            margin: 0;
            flex: 1;
            height: 100%;
            cursor: default;

            .wrapper-button-icon {
                padding: 0.2em;
            }
        }
    }

    .map-container {
        position: relative;
        display: flex;
        flex-grow: 1;
        background-color: fade(@clr-mine-shaft, 0.2);
    }
}

.display.active li:hover {
    text-decoration: line-through;
}

#loopWaypoints(@i) when (@i <= length(@waypointColors)) {
    @color: extract(@waypointColors, @i);

    &:nth-child(@{i}) {
        .arrow {
            fill: @color;
        }

        background-color: @color;

        &:hover {
            .arrow {
                fill: darken(@color, 15%);
            }

            background-color: darken(@color, 15%);

            .magnifier {
                z-index: 2;
                display: block;
            }
        }
    }
    #loopWaypoints(@i+1);
}

@font-family: 'Roboto', sans-serif;@font-size-base: 14px;@primary-color: #039BE5;@menu-dark-bg: #616161;@layout-sider-background: #333333;@tooltip-max-width: 70vw;@form-item-margin-bottom: 6px;@table-row-hover-bg: fade(#039BE5, 30%);@border-radius-base: 0;@btn-border-radius-base: 4px;@tab-horizontal-margin: 0 25px 0 0;@menu-bg: white;@tooltip-bg: rgba(0, 0, 0, .88);